<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {dateUtil} from "@/helpers/date-util";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import CustomCardTitle from "@/components/custom-card/custom-card-title.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {priceHelper} from "../../../helpers/price-helper";


/**
 * E-Wallet Component
 */
export default {

  computed: {
    priceHelper() {
      return priceHelper
    },
    paginationHelper() {
      return paginationHelper
    },

    getSelectedShopId() {
      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          return shopId
        }
      } catch (error) {
        // ignore
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  components: {

    CustomCardSubtitle,
    CustomCardTitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      shop: null,
      market: null,
      summary: null,
      exchangeRates: null,

      payments: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      },

      shop_costs: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      },

      deposits: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      },

      eWallet: {
        potentialProfit: 0.0,
        realProfit: 0.0,
        sumProfit: 0.0,
        payments: 0.0,
        balance: 0.0,
        costs: 0.0,
        withdrawals: 0.0,
        withdrawalRequests: 0.0,
        profitProgramPartner: 0.0
      },

      withdrawModal: {
        visible: false
      },

      form: {
        availableBalance: 0,

        amount: 0,
        firstName: "",
        lastName: "",
        accountNumber: "",
        type: "",
        nationalInsuranceNumber: "",
        companyName: "",
        invoiceFile: null
      },

      selectors: {

        potentialProfitCurrency: null,
        realProfitCurrency: null
      }
    };
  },

  validations() {
    const commonFields = {
      amount: {required},
      firstName: {required},
      lastName: {required},
      accountNumber: {required},
      type: {required},
      nationalInsuranceNumber: {required},
    };

    if (this.form.type === 'COMPANY') {
      return {
        form: {
          ...commonFields,
          companyName: {required},
          invoiceFile: {required},
        },
      };
    }

    return {
      form: commonFields,
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('e-wallet.title'),
          active: true
        }
      ]
    },

    getWithdrawalsFields() {
      return [
        {key: "shopName", label: 'Nazwa'},
        {
          key: "amount", label: 'Kwota', formatter: value => priceHelper.format(value, this.currency())
        },
        {key: "status", label: 'Status'},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value)}
      ]
    },

    getShopCostsFields() {
      return [
        {key: "shopName", label: 'Nazwa'},
        {
          key: "cost", label: 'Kwota', formatter: value => priceHelper.format(value, this.currency()) },
        {key: "description", label: 'Opis'},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value)}
      ]
    },

    getDepositFields() {
      return [
        {key: "shopName", label: 'Nazwa'},
        {
          key: "amount", label: 'Kwota', formatter: value => priceHelper.format(value, this.currency()) },
        {key: "description", label: 'Opis'},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value)}
      ]
    },

    setToFirstPageAndRefresh(table, refName) {
      this.paginationHelper.setToFirstPageAndRefresh(this, table, refName)
    },

    async loadEWallet() {
      if (!this.getSelectedShopId) {
        return
      }

      try {
        const result = await axios.get(`/ewallet/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.eWallet = result.data;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWithdrawals() {
      try {
        let page = this.payments.currentPage - 1;
        if (page > 0) {
          page = this.payments.currentPage * this.payments.perPage - this.payments.perPage;
        }

        const {data} = await axios.get(`/ewallet/withdrawal/pagination/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.payments.perPage
          }
        });

        this.payments.items = data.withdrawals;
        this.payments.totalRows = data.count;
        this.payments.rows = data.count;

        return this.payments.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
        return []
      }
    },

    async loadShopCosts() {
      try {
        let page = this.shop_costs.currentPage - 1;
        if (page > 0) {
          page = this.shop_costs.currentPage * this.shop_costs.perPage - this.shop_costs.perPage;
        }

        const {data} = await axios.get(`/ewallet/shop-cost/pagination/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.shop_costs.perPage
          }
        });

        this.shop_costs.items = data.shopCosts;
        this.shop_costs.totalRows = data.count;
        this.shop_costs.rows = data.count;

        return this.shop_costs.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
        return []
      }
    },

    async loadDeposits() {
      try {
        let page = this.deposits.currentPage - 1;
        if (page > 0) {
          page = this.deposits.currentPage * this.deposits.perPage - this.deposits.perPage;
        }

        const {data} = await axios.get(`/ewallet/deposit/pagination/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.deposits.perPage
          }
        });

        this.deposits.items = data.deposits;
        this.deposits.totalRows = data.count;
        this.deposits.rows = data.count;

        return this.deposits.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
        return []
      }
    },

    getBuffer() {
      const {realProfit, costs, withdrawalRequests, withdrawals, payments} = this.eWallet

      let buffer = ((realProfit - costs - withdrawalRequests - withdrawals + payments) / 4)
      if (buffer < 0) {
        buffer = 0;
      } else if (buffer > 100) {
        buffer = 100;
      }

      return buffer
    },

    openModal() {
      if ((this.eWallet.balance + this.eWallet.payments) <= 0) {
        Swal.fire("", "Twój zysk jest mniejszy niż bufor bezpieczeństwa. Wypłata będzie możliwa po przekroczeniu 400 zł.", "error");
        return
      }

      this.withdrawModal.visible = true

      const availableBalance = Number((this.eWallet.balance + this.eWallet.payments).toFixed(2))
      this.form.availableBalance = availableBalance
      this.form.amount = availableBalance
    },

    hideModal() {
      this.withdrawModal.visible = false
    },

    withdraw() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (this.form.amount > this.form.availableBalance) {
        Swal.fire("Błąd!", `Nie możesz wypłacić większej kwoty niż twój zysk!`, "error")
        return
      }

      const formData = new FormData();
      formData.append("shopId", this.getSelectedShopId)
      formData.append("firstName", this.form.firstName)
      formData.append("lastName", this.form.lastName)
      formData.append("amount", this.form.amount)
      formData.append("accountNumber", this.form.accountNumber)
      formData.append("type", this.form.type)
      formData.append("nationalInsuranceNumber", this.form.nationalInsuranceNumber)

      if (this.form.type === 'COMPANY') {
        formData.append("companyName", this.form.companyName)
        formData.append("invoiceFile", this.form.invoiceFile)
      }

      axios.post(`/ewallet/withdrawal`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        Swal.fire("Sukces!", "Pomyślnie wysłano prośbę o wypłacenie środków!", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    uploadInvoice(input) {
      this.form.invoiceFile = input.target.files[0]
    },

    async loadShop() {
      if (!this.getSelectedShopId) {
        return
      }

      try {
        const {data} = await axios.get(`/shop/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.shop = data
        await this.loadMarket()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarket() {
      try {
        const {data} = await axios.get(`/market/${this.shop.marketId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.market = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadSummary() {
      try {
        const {data: {summary}} = await axios.get(`/ewallet/summary`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.summary = summary
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadExchangeRates() {
      try {
        const {data} = await axios.get(`/exchange-rates/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.exchangeRates = new Map(data.map((obj) => [obj.currency, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    potentialProfitSum() {
      if (!this.summary) {
        return 0.0
      }

      let potentialProfitSum = 0.0
      for (const key in this.summary) {
        const value = this.summary[key];
        for (const shop of value) {
          const exchangeRate = this.getExchangeRate(shop.currency)
          if (!exchangeRate) {
            continue
          }

          potentialProfitSum += shop.potentialProfit * exchangeRate.rate
        }
      }

      return potentialProfitSum / this.selectors.potentialProfitCurrency.rate
    },

    realProfitSum() {
      if (!this.summary) {
        return 0.0
      }

      let realProfitSum = 0.0
      for (const key in this.summary) {
        const value = this.summary[key];
        for (const shop of value) {
          const exchangeRate = this.getExchangeRate(shop.currency)
          if (!exchangeRate) {
            continue
          }

          realProfitSum += shop.realProfit * exchangeRate.rate
        }
      }

      return realProfitSum / this.selectors.realProfitCurrency.rate
    },

    getExchangeRate(currency) {
      if (!this.exchangeRates) {
        return null
      }

      return this.exchangeRates.get(currency) || null
    },

    currency() {
      if (!this.market) {
        return "PLN"
      }

      return this.market.currency
    }

  },

  async created() {
    try {
      await this.loadEWallet()
      await this.loadShop()
      await this.loadSummary()
      await this.loadExchangeRates()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('e-wallet.title')"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <custom-card-title :title="$t('dashboard.ewallet.title.summary')"/>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <table class="table">
                  <thead>
                  <tr>
                    <th colspan="2" class="text-center">{{ $t('dashboard.ewallet.title.actually') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>{{ $t('dashboard.ewallet.summary.potentialProfit') }}</div>
                        <i id="profit-from-orders-in-progress"
                           class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="profit-from-orders-in-progress" placement="right">{{ $t('dashboard.ewallet.summary.tooltip.potentialProfit') }}
                      </b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.potentialProfit, currency()) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>{{ $t('dashboard.ewallet.summary.realProfit') }}</div>
                        <i id="profit" class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="profit" placement="right">{{ $t('dashboard.ewallet.summary.tooltip.realProfit') }}</b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.realProfit + eWallet.payments, currency()) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>{{ $t('dashboard.ewallet.summary.withdrawalRequests') }}</div>
                        <i id="payments-in-progress"
                           class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="payments-in-progress" placement="right">{{ $t('dashboard.ewallet.summary.tooltip.withdrawalRequests') }}</b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.withdrawalRequests, currency()) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>{{ $t('dashboard.ewallet.summary.profitProgramPartner') }}</div>
                        <i id="profit-from-affiliate-program"
                           class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="profit-from-affiliate-program" placement="right">{{ $t('dashboard.ewallet.summary.tooltip.profitProgramPartner') }}
                      </b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.profitProgramPartner, currency()) }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>Przewidywane prowizje Allegro do zapłaty</div>
                        <i id="expected-profit-allegro" class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="expected-profit-allegro" placement="right">Przewidywane prowizje Allegro do zapłaty</b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.potentialAllegroProvisions, currency()) }}</td>
                  </tr>

                  <tr>
                    <td>
                      <div class="my-1 d-inline-flex">
                        <div>Rozliczone prowizje Allegro</div>
                        <i id="settled-allegro-provisions" class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      </div>
                      <b-tooltip target="settled-allegro-provisions" placement="right">Rozliczone prowizje Allegro</b-tooltip>
                    </td>
                    <td class="price text-gray-900">{{ priceHelper.format(eWallet.settledAllegroProvisions, currency()) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-lg-6">
                <div>
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="3" class="text-center">{{ $t('dashboard.ewallet.title.archives') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <div class="my-1">
                          {{ $t('dashboard.ewallet.summary.costs') }}
                        </div>
                      </td>
                      <td class="price text-gray-900">{{ priceHelper.format(eWallet.costs, currency()) }}</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="my-1">
                          {{ $t('dashboard.ewallet.summary.withdrawals') }}
                        </div>
                      </td>
                      <td class="price text-gray-900">{{ priceHelper.format(eWallet.withdrawals, currency()) }}</td>
                    </tr>
                    </tbody>
                  </table>

                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="3" class="text-center">{{ $t('dashboard.ewallet.title.buffer') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="col-lg-1">
                      <td class="text-center">
                        <template
                            v-if="eWallet.realProfit - eWallet.costs - eWallet.withdrawalRequests - eWallet.withdrawals + eWallet.payments < 0">
                          0.00
                        </template>
                        <template
                            v-else-if="eWallet.realProfit - eWallet.costs - eWallet.withdrawalRequests - eWallet.withdrawals + eWallet.payments < eWallet.buffer">
                          {{
                            (eWallet.realProfit - eWallet.costs - eWallet.withdrawalRequests - eWallet.withdrawals + eWallet.payments).toFixed(2)
                          }}
                        </template>
                        <template v-else>
                          {{ eWallet.buffer }}
                        </template>
                      </td>

                      <td class="col-lg-10">
                        <div class="progress progress-bar-primary my-2"
                             style="background-color: #191D25;">
                          <div aria-valuemax="100" aria-valuemin="25" aria-valuenow="2" class="progress-bar"
                               role="progressbar" :style="`width: ${getBuffer()}%;`"></div>
                        </div>
                      </td>

                      <td class="col-lg-1 text-center">
                        <template v-if="eWallet.realProfit / 4 > 100">
                          100%
                        </template>
                        <template v-else>
                          {{ (eWallet.realProfit / 4).toFixed(2) }}%
                        </template>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <div class="card-title my-1 d-inline-flex">
                <div>{{ $t('dashboard.ewallet.summary.withdrawTotal') }}</div>
                <i id="amount-to-be-paid" class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
              </div>
              <b-tooltip target="amount-to-be-paid" placement="right">{{ $t('dashboard.ewallet.summary.tooltip.withdrawTotal') }}</b-tooltip>

              <h1>{{ priceHelper.format(eWallet.balance + eWallet.payments + eWallet.profitProgramPartner, currency()) }}</h1>

              <template
                  v-if="eWallet.realProfit - eWallet.withdrawalRequests - eWallet.costs + eWallet.payments - eWallet.buffer > 0">
                <a class="btn btn-success" @click="openModal">
                  Wypłać
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <custom-card-title :title="$t('dashboard.ewallet.title.payouts')"/>
          <div class="card-body">
            <ecat-table
                :table="payments"
                :fields="getWithdrawalsFields()"
                :items="loadWithdrawals"


            />
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <custom-card-title :title="$t('dashboard.ewallet.title.storeCosts')"/>
          <div class="card-body">
            <ecat-table
                :table="shop_costs"
                :fields="getShopCostsFields()"
                :items="loadShopCosts"


            />
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="card">
          <custom-card-title :title="$t('dashboard.ewallet.title.deposits')"/>
          <div class="card-body">
            <ecat-table
                :table="deposits"
                :fields="getDepositFields()"
                :items="loadDeposits"


            />
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <custom-card-title :title="$t('dashboard.ewallet.title.summaryAllStores')"/>
          <div class="card-body">
            <div class="row py-1">
              <custom-card-subtitle :title="$t('dashboard.ewallet.summaryAllStores.title')"/>

              <div class="col-lg-6">
                <div v-if="summary && exchangeRates">
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="2" class="text-center">{{ $t('dashboard.ewallet.summaryAllStores.potentialProfitTitle') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(value, index) in summary">
                      <tr :key="index">
                        <td colspan="2" class="text-center">{{ $t(value[0].marketI18nTranslation) }}</td>
                      </tr>

                      <tr v-for="(wallet, walletIndex) in value" :key="`${index}-${walletIndex}`">
                        <td>
                          <div class="my-1 text-center">
                            {{ wallet.shopName }}
                          </div>
                        </td>
                        <td class="price text-gray-900">{{ priceHelper.format(wallet.potentialProfit, wallet.currency) }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>

                  <div class="row mx-0 py-3 card-custom">
                    <div class="col-lg-6">
                      {{ $t('dashboard.ewallet.summaryAllStores.profitSum') }}
                      <ecat-multiselect
                          @change-object="value => selectors.potentialProfitCurrency = value"
                          view-id="currency"
                          save-id="id"
                          :can-unselect="true"
                          placeholder="Wybierz walutę"
                          load-url="/exchange-rates/pagination"
                          query-url="/exchange-rates/by-currency"
                          param="currency"
                      />
                    </div>

                    <div class="col-lg-6 price text-gray-900 align-self-center">
                      <template v-if="selectors.potentialProfitCurrency">
                        {{ priceHelper.format(potentialProfitSum(), selectors.potentialProfitCurrency.currency) }}
                      </template>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center py-3">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div v-if="summary && exchangeRates">
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="2" class="text-center">{{ $t('dashboard.ewallet.summaryAllStores.realProfitTitle') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(value, index) in summary">
                      <tr :key="index">
                        <td colspan="2" class="text-center">{{ $t(value[0].marketI18nTranslation) }}</td>
                      </tr>

                      <tr v-for="(wallet, walletIndex) in value" :key="`${index}-${walletIndex}`">
                        <td>
                          <div class="my-1 text-center">
                            {{ wallet.shopName }}
                          </div>
                        </td>
                        <td class="price text-gray-900">{{ priceHelper.format(wallet.realProfit, wallet.currency) }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>

                  <div class="row mx-0 py-3 card-custom">
                    <div class="col-lg-6">
                      {{ $t('dashboard.ewallet.summaryAllStores.profitSum') }}
                      <ecat-multiselect
                          @change-object="value => selectors.realProfitCurrency = value"
                          view-id="currency"
                          save-id="id"
                          :can-unselect="true"
                          placeholder="Wybierz walutę"
                          load-url="/exchange-rates/pagination"
                          query-url="/exchange-rates/by-currency"
                          param="currency"
                      />
                    </div>

                    <div class="col-lg-6 price color-text-white align-self-center text-gray-900">
                      <template v-if="selectors.realProfitCurrency">
                        {{ priceHelper.format(realProfitSum(), selectors.realProfitCurrency.currency) }}
                      </template>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center py-3">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        centered
        id="modal-10"
        v-model="withdrawModal.visible"
        hide-footer
        title="Wypłata środków z e-portfela"
        title-class="font-18"
        @esc="hideModal"
        @hide="hideModal">
      <div class="form-group">
        <label>Kwota</label>
        <input v-model="form.amount" :max="form.availableBalance" type="number"
               class="form-control custom-number-with-gap" min="1" step="0.01"
               :class="{ 'is-invalid': $v.form.amount.$error }"/>
        <div v-if="!$v.form.amount.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Imię</label>
        <input v-model="form.firstName" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.firstName.$error }"/>
        <div v-if="!$v.form.firstName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Nazwisko</label>
        <input v-model="form.lastName" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.lastName.$error }"/>
        <div v-if="!$v.form.lastName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Nr konta</label>
        <input v-model="form.accountNumber" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.accountNumber.$error }"/>
        <div v-if="!$v.form.accountNumber.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group">
        <label>Wypłacam jako:</label>

        <div>
          <input type="radio" id="PHYSICAL_PERSON" value="PHYSICAL_PERSON" v-model="form.type" name="PHYSICAL_PERSON"
                 :class="{ 'is-invalid': $v.form.type.$error }">
          <label for="PHYSICAL_PERSON" style="margin-left: 5px;">Osoba fizyczna</label>
        </div>

        <div>
          <input type="radio" id="COMPANY" value="COMPANY" v-model="form.type" name="COMPANY"
                 :class="{ 'is-invalid': $v.form.type.$error }">
          <label for="COMPANY" style="margin-left: 5px;">Firma</label>
        </div>

        <div v-if="$v.form.type.$error && !$v.form.type.required" class="text-danger">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group" v-if="form.type === 'PHYSICAL_PERSON'">
        <label>Pesel</label>
        <input v-model="form.nationalInsuranceNumber" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.nationalInsuranceNumber.$error }"/>
        <div v-if="!$v.form.nationalInsuranceNumber.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group" v-if="form.type === 'COMPANY'">
        <label>Nazwa firmy</label>
        <input v-model="form.companyName" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.companyName.$error }"/>
        <div v-if="!$v.form.companyName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="form-group" v-if="form.type === 'COMPANY'">
        <label>NIP</label>
        <input v-model="form.nationalInsuranceNumber" type="text" class="form-control"
               :class="{ 'is-invalid': $v.form.nationalInsuranceNumber.$error }"/>
        <div v-if="!$v.form.nationalInsuranceNumber.required" class="invalid-feedback">{{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group" v-if="form.type === 'COMPANY'">
        <label>Plik faktury</label>

        <div>
          <input type="file" id="invoice" @change="uploadInvoice" :class="{ 'is-invalid': $v.form.invoiceFile.$error }">
          <div v-if="!$v.form.invoiceFile.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="withdraw">
          Wypłać
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

  </Layout>
</template>